










import { Component, Vue } from 'vue-property-decorator'
import { Project } from '@/model/Project'
import { User } from '@/model/User'

@Component({
  components: {},
})
export default class ViewSuperadmin extends Vue {
  createAccount() {
    const user: User = {
      firstname: 'Bob',
      lastname: 'Doe',
      email: 'email' + Math.random(),
    }
    const project: Project = {
      address: '33 rue du moulin',
      city: 'Moulinville',
      postalCode: '57630',
    }
  }
}
